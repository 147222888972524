<template>
  <b-card>
    <b-card-header>
      <strong>Repositorio de calidad y seguridad</strong>
    </b-card-header>
    <!-- show-checkbox     -->
    <b-card-body>
      <b-form-input
        v-model="search"
        class="mb-1"
        placeholder="Buscar..."
      />
      <v-jstree
        ref="refJsTree"
        size="large"
        :data="elements"
        multiple
        allow-batch
        whole-row
        :collapse.sync="collapse"
        @item-click="() => {}"
      >
        <template scope="_">
          <div
            style="display: inherit; width: 200px"
            @click.ctrl="customItemClickWithCtrl"
            @click.exact="itemClick(_.vm, _.model, $event)"
          >
            <feather-icon
              v-if="!_.model.loading"
              size="25"
              :icon="_.model.icon ? _.model.icon : 'FolderIcon'"
            />
            {{ _.model.text }}
          </div>
        </template>
      </v-jstree>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BFormInput,
} from 'bootstrap-vue'
import router from '@/router'
import { ref, watch } from '@vue/composition-api'
import VJstree from 'vue-jstree'
import _ from 'lodash'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    VJstree,
  },
  setup() {
    const refJsTree = ref(null)
    const elements = ref([])
    const search = ref('')
    const collapse = ref(true)
    const findAll = (text, items) => {
      let i = 0
      let found
      let result = []
      // eslint-disable-next-line no-plusplus
      for (; i < items.length; i++) {
        if (items[i].text.toLowerCase().includes(text.toLowerCase())) {
          result.opened = true
          result.push(items[i])
        } else if (_.isArray(items[i].children)) {
          found = findAll(text, items[i].children)
          if (found.length) {
            result = result.concat(found)
          }
        }
      }

      return result
    }
    const itemClick = (node, item) => {
      if (item.id) {
        if (item.text.endsWith('.pdf')) {
          const routeData = router.resolve({ name: 'pages-pdf-viewer', params: { pdfUrl: `${process.env.VUE_APP_ADUACORE_API_URL}/api/Archivos/${item.id}` } })
          window.open(routeData.href, '_blank')
        } else {
          window.open(`${process.env.VUE_APP_ADUACORE_API_URL}/api/Archivos/${item.id}`, '_blank')
        }
      }
    }
    const data = ref([
      {
        text: '0. Mision, vision y valores',
        // state...: true,
        icon: 'FolderIcon',
        children: [
          {
            id: 'a208f22a-cdd3-4be4-babc-18f63fef2123',
            text: 'Mision.pdf',
            icon: 'FileTextIcon',
          },
        ],
      },
      {
        text: '1. Politica de calidad y seguridad',
      },
      {
        text: '2. Objeticos de calidad y seguridad',
      },
      {
        text: '3. Estructura Organizacional',
      },
      {
        text: '05. Procesos SGCS (Sistema de Gestion de Calidad y Seguridad)',
        children: [
          {
            text: 'Administración',
            children: [
              {
                text: 'AMEF´s',
                children: [
                  {
                    text: 'AMEF (Compras).pdf',
                    id: '7c7cbb1a-d78c-43c5-a198-e6d91f2c51f1',
                    icon: 'FileTextIcon',
                  },
                ],
              },
            ],
          },
        ],
      },
    ])

    elements.value = data.value

    const filterData = () => {
      elements.value = findAll(search.value, data.value)
    }

    watch(search, () => {
      filterData()
      collapse.value = search.value !== ''
    })

    return {
      collapse,
      refJsTree,
      elements,
      search,
      itemClick,
    }
  },
}
</script>

<style>

</style>
